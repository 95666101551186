import React, { FunctionComponent } from 'react'
import IconMenu from './IconMenu'

import Logo from '../img/waffle-studio-logo-text.svg'
import WaffleStudioLogo from './WaffleStudioLogo'

type HeaderProps = {
	onClickMenu?: () => void
	subtitle?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Header: FunctionComponent<HeaderProps> = ({ onClickMenu, subtitle }) => (
	<header>
		<div className="header__left">
			<IconMenu onClick={onClickMenu} />
		</div>
		<div className="header__middle">
			<WaffleStudioLogo />
		</div>
		<div className="header__right" />
	</header>
)

Header.defaultProps = {
	onClickMenu: undefined,
	subtitle: '',
}

export default Header
