import React, { FunctionComponent } from 'react'

import WaffleAndPopcornPng from '../img/waffle-and-popcorn.png'

const WaffleAndPopcorn: FunctionComponent = () => (
	<div className="waffle-and-popcorn">
		<img src={WaffleAndPopcornPng} alt="Mr Waffle" />
	</div>
)

export default WaffleAndPopcorn
