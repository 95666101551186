import React, { FunctionComponent } from 'react'

import ProfessorCluckPng from '../img/professor-cluck.png'

type ProfessorCluckProps = {
	//
}

const ProfessorCluck: FunctionComponent<ProfessorCluckProps> = props => {
	return (
		<div className="professor-cluck">
			<img src={ProfessorCluckPng} alt="Professor Cluck" />
		</div>
	)
}

export default ProfessorCluck
