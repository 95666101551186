import React, { FunctionComponent } from 'react'

import giffleImage from '../img/giffle2.png'

type GiffleProps = {
	//
}

const Giffle: FunctionComponent<GiffleProps> = props => (
	<div className="giffle">
		<img src={giffleImage} alt="Giffle" />
	</div>
)

export default Giffle
