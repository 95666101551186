import React, { FunctionComponent, useState } from 'react'
import Row from './Row'
import { useMount } from 'react-use'

type StatsProps = {
	//
}

/// {"month":1,"year":2025,"monthlyAverage":469811,"monthlySum":14564143,"totalSum":472890270}
type StatsObj = {
	month: number
	year: number
	monthlyAverage: number
	monthlySum: number
	totalSum: number
}

const Stats: FunctionComponent<StatsProps> = props => {
	const [stats, setStats] = useState<StatsObj>({} as StatsObj)

	useMount(async () => {
		// Fetch stats from a JSON object (written by a scheduled server function)
		const statsReq = await fetch('https://waffle.dev/stats.json')
		const statsObj = await statsReq.json()
		setStats(statsObj)
	})

	// prettier-ignore
	const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ]

	if (!stats.month) return null

	const formatNumber = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	return (
		<div className="section stats">
			<div className="stats__inner">
				<h3>
					{monthNames[stats.month - 1]} {stats.year}
				</h3>
				<div>
					<h4>Average daily players</h4>
					<span className="number">{formatNumber(stats.monthlyAverage)}</span>
				</div>
				<div>
					<h4>Monthly Waffles solved</h4>
					<span className="number">{formatNumber(stats.monthlySum)}</span>
				</div>
				<div>
					<h4>Total Waffles solved</h4>
					<span className="number">{formatNumber(stats.totalSum)}</span>
				</div>
			</div>
		</div>
	)
}

export default Stats
