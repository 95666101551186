/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent } from 'react'

// prettier-ignore
const LifehackerLogo: FunctionComponent = () => (
	<a 
		className="logo lifehacker-logo"
		target="_blank"
		rel="noreferrer"
		href="https://lifehacker.com/waffle-is-the-new-wordle-1848793653"
	>
		<svg viewBox="0 0 1497 414">
			<path fill="#363636" d="M0 20h20v374H0zM1477 20h20v374h-20zM1431.35 394v20H63v-20zM1430.91 0v20H62.56V0zM568.595 332.872V81.252h36.972v104.072h28.757V81.253h36.972v251.619h-36.972V223.323h-28.757v109.549h-36.972ZM708.057 332.872l38-251.62h45.189l37.999 251.62H793.3l-6.847-51.351H750.85l-6.847 51.351h-35.946Zm47.928-90.378h25.333l-10.612-84.558-1.028-13.351h-2.054l-1.027 13.351-10.612 84.558ZM913.52 335.61c-10.499 0-19.457-2.339-26.874-7.018-7.303-4.792-12.895-11.297-16.775-19.513-3.765-8.216-5.648-17.516-5.648-27.901V132.946c0-10.27 1.94-19.514 5.82-27.73 3.993-8.216 9.699-14.72 17.116-19.513 7.418-4.793 16.204-7.19 26.361-7.19 7.645 0 14.492 1.427 20.54 4.28 6.048 2.853 11.183 6.79 15.405 11.81 4.336 4.908 7.646 10.67 9.928 17.289 2.282 6.504 3.423 13.522 3.423 21.054v34.576H928.24v-34.576c0-5.249-1.084-9.643-3.252-13.18-2.168-3.538-5.763-5.307-10.784-5.307-4.564 0-7.874 1.826-9.928 5.478-2.054 3.652-3.081 7.988-3.081 13.009v148.232c0 5.021 1.084 9.358 3.253 13.009 2.282 3.652 5.534 5.478 9.756 5.478 5.135 0 8.73-1.769 10.784-5.307 2.168-3.537 3.252-7.93 3.252-13.18v-34.576h34.576v34.576c0 10.271-1.997 19.514-5.991 27.73-3.994 8.216-9.699 14.72-17.117 19.513-7.303 4.793-16.032 7.189-26.188 7.189ZM1005.74 332.872V81.252h36.98v87.982h1.37l1.71-4.108 28.76-83.873h37.99l-33.89 82.846 38.35 168.773h-38l-24.65-114.684h-1.03l-10.61 25.675v89.009h-36.98ZM1151.24 332.872V81.252h84.56v38h-47.59v67.783h34.24v36.973h-34.24v70.864h47.59v38h-84.56ZM1280.35 332.872V81.252h36.97c22.14 0 38.46 6.163 48.96 18.487 10.5 12.21 15.75 30.24 15.75 54.089 0 14.15-2.51 26.418-7.54 36.802-4.9 10.27-10.89 17.801-17.97 22.594l25.16 119.648h-36.97l-20.88-106.125h-6.51v106.125h-36.97Zm36.97-140.359c6.85 0 12.27-1.484 16.27-4.451 3.99-3.081 6.84-7.417 8.55-13.009 1.72-5.591 2.57-12.21 2.57-19.855 0-11.982-2-21.168-5.99-27.558-3.88-6.505-11.01-9.757-21.4-9.757v74.63Z" />
			<path fill="#54B560" d="M120.587 332.958V81.524h36.945V298.75h48.919v34.208h-85.864Z" />
			<path fill="#54B560" d="M238.58 332.958V81.524h36.946v251.434H238.58Z" />
			<path fill="#54B560" d="M320.16 332.958V81.524h87.916v37.972h-50.971v66.023h34.209v37.972h-34.209v109.467H320.16Z" />
			<path fill="#54B560" d="M440.158 332.958V81.524h84.495v37.972h-47.55v67.733h34.209v36.946h-34.209v70.812h47.55v37.971h-84.495Z" />
		</svg>
	</a>
)

export default LifehackerLogo
