import React, { FunctionComponent } from 'react'

export type IconButtonProps = {
	name: string
	onClick?: () => void
}

export type IconButtonComponentProps = Omit<IconButtonProps, 'name'>

const IconButton: FunctionComponent<IconButtonProps> = ({ children, name, onClick }) => {
	let className = 'icon'
	if (name) className += ` icon-${name}`

	return (
		<div className={className} onClick={onClick}>
			{children}
		</div>
	)
}

IconButton.defaultProps = {
	onClick: undefined,
}

export default IconButton
