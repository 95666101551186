/* eslint-disable no-shadow */
import React, { FunctionComponent } from 'react'
import Header from './components/Header'
import Row from './components/Row'

import onewordsearch from './img/onewordsearch.png'
import IndependentLogo from './components/IndependentLogo'
import LifehackerLogo from './components/LifehackerLogo'
import TheVergeLogo from './components/TheVergeLogo'
import CnetLogo from './components/CnetLogo'
import Section from './components/Section'
import OneWordSearch from './components/OneWordSearch'
import Stats from './components/Stats'
import Waffle from './components/Waffle'
import Giffle from './components/Giffle'
import MrWaffle from './components/MrWaffle'
import ProfessorCluck from './components/ProfessorCluck'
import WaffleAndPopcorn from './components/WaffleAndPopcorn'
// import MenuBar from './components/MenuBar'

const App: FunctionComponent = () => (
	<div className="app">
		<Header subtitle="Waffle Studio" />
		{/* <MenuBar /> */}
		<br />
		<br />
		<p>
			Hello! How are you?
			<br />
			Please allow me to introduce our games&nbsp;studio.
			<br />
			We make games, like this:
		</p>
		<div className="section">
			<Waffle />
		</div>

		<MrWaffle />

		<p>
			That&apos;s&nbsp;
			<a href="https://wafflegame.net" target="_blank" rel="noreferrer">
				Waffle
			</a>
			. I created that in 2022, and it has been quite popular, so we&apos;ve carried on from there, really.
		</p>

		<Stats />

		<p>We&apos;ve had a lot of press about it...</p>
		<Section>
			<LifehackerLogo />
			<IndependentLogo />
			<TheVergeLogo />
			<CnetLogo />
		</Section>

		<p>
			So now we&apos;re a studio, and we have a lot of ideas that we want to bring to life, such as this one:{' '}
			<a href="https://onewordsearch.com" target="_blank" rel="noreferrer">
				One&nbsp;Word&nbsp;Search
			</a>
		</p>

		<ProfessorCluck />
		<div className="section">
			<OneWordSearch />
		</div>

		<p>
			We also have{' '}
			<a href="https://giffle.com" target="_blank" rel="noreferrer">
				giffle
			</a>
			, the TV episode GIF guessing game...
		</p>
		<div className="section">
			<Giffle />
		</div>
		<WaffleAndPopcorn />

		<p>We are just getting started! If you are interested in helping us in any way, please get in touch.</p>
		<br />
		<a href="mailto:hello@waffle.dev"> hello@waffle.dev </a>
		<br />
	</div>
)

export default App
