/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent } from 'react'

// style = 'enable-background:new 0 0 398.7 89.3'
const TheVergeLogo: FunctionComponent = () => (
	<a
		className="logo the-verge-logo"
		target="_blank"
		rel="noreferrer"
		href="https://lifehacker.com/waffle-is-the-new-wordle-1848793653"
	>
		<svg viewBox="0 0 398.7 89.3">
			<path
				d="m299.3 22.8-.4 11.7c-13.7-.9-17.6 2.6-17.6 14.1v1.5h-11.8V23.5h11.8v7.6c3.5-6.2 8.3-9 18-8.3zM19.6 70.5h11.8V25.1H19.6v45.4zm58.2-47.6c-8.2 0-13.2 3.7-15.9 7.7v-25H0v11h50.9v33.6h11.2c0-9.6 5.6-16.4 12.4-16.4 5.9 0 8.5 2.9 8.5 13.3v23.6h11.8v-28c0-13.3-6.8-19.8-17-19.8zM124 61.5c-7.1 0-11.8-4.3-12.5-11.4h-11c1.1 12.5 9.3 21.4 23.5 21.4 11 0 17.2-4.9 20.4-12.4l-10-4.4c-1.5 4.5-4.3 6.8-10.4 6.8zm-.4-38.4c-4.2-.1-8.3.9-12.1 2.8l5.6 8c2.1-1.1 4.4-1.6 6.8-1.6 6.6 0 9.9 4.3 10.4 9.9H123V50h22.4c.2-1.2.2-2.3.2-3.4.1-17-11.2-23.5-22-23.5zm116.3 38.4c-7.1 0-11.8-4.3-12.5-11.4h-11c1.1 12.5 9.3 21.4 23.5 21.4 11 0 17.2-4.9 20.4-12.4l-10-4.4c-1.6 4.5-4.4 6.8-10.4 6.8zm-.4-38.4c-4.2-.1-8.3.9-12.1 2.8l5.6 8c2.1-1.1 4.4-1.6 6.8-1.6 6.6 0 10 4.3 10.4 9.9H239V50h22.4c.2-1.2.2-2.3.2-3.4.1-17-11.2-23.5-22.1-23.5zm137.7 38.4c-7.1 0-11.8-4.3-12.5-11.4h-11c1.1 12.5 9.3 21.4 23.5 21.4 11 0 17.2-4.9 20.4-12.4l-10-4.4c-1.6 4.5-4.3 6.8-10.4 6.8zm-.6-38.4c-4.2-.1-8.3.9-12.1 2.8l5.6 8c2.1-1.1 4.4-1.6 6.8-1.6 6.6 0 9.9 4.3 10.4 9.9h-11.4V50h22.4c.2-1.2.2-2.3.2-3.4.3-17-11-23.5-21.9-23.5zM151.7 0l21.1 50.1h12.8l-16.1-39.8h20.8V.1L151.7 0zm62.7.1-24.2 61.3-3.6 9.2h12.3L228 .1h-13.6zm108 69.3c5 0 9.2-1.4 12.1-3.8l-4.7-7.4c-1.5.6-3.1.9-4.8.9-6.4 0-10.1-3.8-11.4-8.9h-11.5c1.6 10.8 9.4 19.2 20.3 19.2zm14.5-39.3c-2.7-4.2-8.1-7.3-15.6-7.3-7.4-.1-14.2 3.9-17.8 10.4l9.8 6.3c1.8-3.8 5.3-6.3 10.7-6.3 7.7 0 12.8 5.9 12.8 13 0 1.3-.2 2.7-.5 3.9h12.3V23.6h-11.8l.1 6.5zm-11.4 50c-7.6 0-10.4-3.7-11-8h-11.2c.2 8.2 5.7 17.2 22.4 17.2 11.3 0 18.6-5.6 21.7-14.1l-10.6-3.7c-1.4 5.5-5.5 8.6-11.3 8.6z"
				style={{ fill: '#131313' }}
			/>
		</svg>
	</a>
)

export default TheVergeLogo
