/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react'
import IconButton, { IconButtonComponentProps } from './IconButton'

const IconMenu: FunctionComponent<IconButtonComponentProps> = props => (
	<IconButton name="menu" {...props}>
		<svg viewBox="0 0 100 100">
			<path d="M94.7 75.65q-2.25-.75-7.5-.75H12.7q-5.1 0-7.5.75-5.25 1.65-5.25 11.6 0 9.15 3.95 11.25 2.05 1.1 3.7 1.3 1.7.2 4.9.2l79.75-.2q2.1-.4 3.75-1.3 3.95-2.1 3.95-11.25 0-9.95-5.25-11.6m0-74.9Q92.45 0 87.2 0H12.7Q7.6 0 5.2.75-.05 2.4-.05 12.35q0 9.15 3.95 11.25 2.05 1.1 3.7 1.3 1.7.2 4.9.2l79.75-.2q2.1-.4 3.75-1.3 3.95-2.1 3.95-11.25 0-9.95-5.25-11.6m0 37.3q-2.25-.75-7.5-.75H12.7q-5.1 0-7.5.75-5.25 1.65-5.25 11.6 0 9.15 3.95 11.25 2.05 1.1 3.7 1.3 1.7.2 4.9.2l79.75-.2q2.1-.4 3.75-1.3 3.95-2.1 3.95-11.25 0-9.95-5.25-11.6Z" />
		</svg>
	</IconButton>
)

export default IconMenu
